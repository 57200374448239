<template>
  <div>
    <b-row>
      <b-col class="col-md-2">
        <BFormGroup
          label="Поиск"
        >
          <BFormInput
            v-model="filter.search"
            type="search"
          />
        </BFormGroup>
      </b-col>

      <b-col class="col-md-2">
        <BFormGroup
          label="Оператор"
        >
          <v-select
            v-model="filter.DK_OPERATOR"
            class="bg-white rounded"
            :options="users.results"
            label="full_name"
            :reduce="p => p.id"
          />
        </BFormGroup>
      </b-col>

      <BCol md="2">
        <BFormGroup label="Взыскатель">
          <VSelect
            v-model="filter.CLAIMER_CRM_COMPANY_ID"
            class="bg-white rounded"
            label="COMPANY_NAME"
            :reduce="p =>p.COMPANY_ID"
            :options="debtCompanyList.results"
          />
        </BFormGroup>
      </BCol>
    </b-row>

    <b-overlay
      :show="loader"
      rounded="sm"
    >
      <b-card class="mt-1">
        <b-table
          :fields="fields"
          :items="allOperatorList.results"
          show-empty
          hover
          responsive
          @row-clicked="onRowSelected"
        >
          <template #empty>
            <empty />
          </template>
          <template #cell(DEBT)="props">
            <div v-if="props.item.BASIS">
              {{ $_moneyFormatter(props.item.BASIS.DEBT) }}
            </div>
          </template>
          <template #cell(DO_STAGE)="props">
            <div v-if="props.item.BASIS">
              {{ DO_STAGE_TEXT(props.item.BASIS.DO_STAGE) }}
            </div>
          </template>
          <template #cell(DEBT_STATUS)="props">
            <b-badge
              v-if="props.item.LAST_ACTION_NAME"
              :variant="props.item.LAST_ACTION_NAME"
            >
              {{ getTextDebtStatus(props.item.LAST_ACTION_NAME) }}
            </b-badge>
          </template>
        </b-table>
      </b-card>

      <b-card>
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap mr-1">Показать по</span>
            <b-form-select
              v-model="filter.page_size"
              :options="['5', '10', '20']"
              class="mx-1"
              @change="refresh"
            />
            <span class="ml-1 text-nowrap"> строк( {{ allOperatorList.count }} )</span>
          </div>
          <div>
            <b-pagination
              v-model="filter.page"
              :total-rows="allOperatorList.count"
              :per-page="filter.page_size"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="changePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BFormGroup,
  BFormInput,
  BRow,
  BCol, BFormSelect, BPagination, BBadge, BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapMutations, mapState } from 'vuex'
import empty from '@/components/empty.vue'
import VSelect from 'vue-select'

export default {
  components: {
    BOverlay,
    VSelect,
    BBadge,
    BPagination,
    BFormSelect,
    BCard,
    BTable,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    empty,
  },
  data() {
    return {
      loader: false,
      filter: {
        search: null,
        DK_OPERATOR: null,
        CLAIMER_CRM_COMPANY_ID: null,
        page: 1,
        page_size: 20,
      },
      fields: [
        {
          label: 'Номер договора',
          key: 'GUID',
        },
        {
          label: 'ФИО должника',
          key: 'BORROWER',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Наименование портфеля',
          key: 'CLAIMER_CRM_COMPANY_ID',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Сумма долга',
          key: 'DEBT',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Оператор',
          key: 'DK_OPERATOR.full_name',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'СТАТУС',
          key: 'DEBT_STATUS',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
        {
          label: 'Дата последнего действия',
          key: 'LAST_ACTION_DATE',
          thClass: 'tableHeadClass',
          tdClass: 'tableHeadClass',
        },
      ],
    }
  },
  computed: {
    ...mapState('cases', ['debtCompanyList']),
    ...mapState('dk', ['users']),
    ...mapState('todoList', ['allOperatorList']),
  },
  watch: {
    'filter.search': {
      handler(val) {
        if (val) {
          clearTimeout(this.time)
          this.time = setTimeout(() => {
            this.refresh()
          }, 500)
        } else {
          this.refresh()
        }
      },
    },
    'filter.DK_OPERATOR': {
      handler() {
        this.refresh()
      },
    },
    'filter.CLAIMER_CRM_COMPANY_ID': {
      handler() {
        this.refresh()
      },
    },
  },
  mounted() {
    const req = {
      page_size: 1000,
    }
    this.FETCH_USERS({ role_unique_name: 'dk', ...req })
    this.FETCH_SK_DEBT_COMPANY_LIST(req)
    this.FETCH_DK_ALL_OPERATOR(this.filter)
  },
  methods: {
    ...mapActions('todoList', ['FETCH_DK_ALL_OPERATOR']),
    ...mapActions('dk', ['FETCH_USERS']),
    ...mapActions('cases', ['FETCH_SK_DEBT_COMPANY_LIST']),
    ...mapMutations('collectorDetail', ['SET_ACTIVE', 'SET_CALL']),

    async onRowSelected(params) {
      try {
        this.SET_ACTIVE(3)
        this.SET_CALL(false)

        await this.$router.push({
          name: 'collector-id',
          params: {
            id: params.id,
          },
        })
      } catch (e) {
        await this.$_errorToast(e)
      }
    },

    async refresh() {
      try {
        this.loader = true
        await this.FETCH_DK_ALL_OPERATOR(this.filter)
      } catch (e) {
        this.loader = false
        await this.$_errorToast(e)
      }
      this.loader = false
    },

    getTextDebtStatus(value) {
      const message = {
        secondary: 'Неправильный номер',
        primary: 'Связь прервалась',
        'light-danger': 'Отказ',
        dark: 'Банкрот',
        danger: 'Неперспективный',
        info: 'Не ответил',
        warning: 'Обещание',
      }

      return message[value] || ''
    },

    changePage(page) {
      this.filter.page = page
      this.refresh()
    },

    DO_STAGE_TEXT(value) {
      const message = {
        voluntary_payment: 'Добровольная оплата',
        claim: 'Претензия',
        judical_work: 'Судебная работа',
        executive_document: 'Исполнительный документ',
        executive_work: 'Исполнительное производство',
        stopped: 'Приостановлено',
        paid: 'Погашен',
      }

      return message[value] || ''
    },
  },
}
</script>
